<template>
  <div class="home">
    <h3>strBurst Manager</h3>
    <p>Welcome to the strBurst Manager.<br>
    Select an option from the menu.</p>
    <p class="strburst-left">You can:</p>
    <ul class="strburst-left">
      <li>Scan and redeem vouchers</li>
      <li>Register a new user</li>

    </ul>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
