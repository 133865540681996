import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/scan',
        name: 'scan',
        component: () => import('../views/ScanCode.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('../views/SignUp.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/postsignup',
        name: 'postSignup',
        component: () => import('../views/PostSignup.vue')
    },
    {
        path: '/postscan',
        name: 'postScan',
        component: () => import('../views/PostScan.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginScreen.vue')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
