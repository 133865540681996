<template>

  <div class="header-div">
    <img class="header-image" alt="strBurst Manager" src="./assets/starburst-logo-2.png">
  </div>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/scan">Scan</router-link> |
    <router-link to="/signup">Register User</router-link>
  </nav>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
#app {

  font-family: Poppins, Ubuntu, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
