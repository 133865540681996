import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './main.css'
import "bootstrap/dist/js/bootstrap.js"
import VueQrcodeReader from "vue3-qrcode-reader"
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
    .use(store)
    .use(router)
    .use(pinia)
    .use(VueQrcodeReader)
    .mount('#app')
